import React, { useEffect, useState } from "react";
import copy from 'clipboard-copy';

import { getFooter } from "../../handleData";
import Image from "../components/image";
import Pdf from "../components/pdf";

function Footer({ language }) {
    const [sites, setSites] = useState([]);
    const [contact, setContact] = useState([]);
    const [logo, setLogo] = useState([]);
    const [copyright, setCopyright] = useState([]);

    useEffect(() => {
        if (language) {
            getFooter(language).then(result => {
                setSites(result[0]);
                setContact(result[1]);
                setLogo(result[2]);
                setCopyright(result[3]);
            });
        }
    }, [language]);

    const copyToClip = (event) => {
        const content = event.target.textContent;
        copy(content).then(() => {
            const icon = (event.target.querySelector('i'));
            icon.classList.remove("fa-clipboard");
            icon.classList.add("fa-clipboard-check");
        }).catch((error) => {
            console.log('Copying failed', error)
        });
    };

    const addIcon = (event) => {
        const icon = event.target.querySelector('i') || event.target;
        if (icon) {
            icon.style.opacity = '1';
        }
    }

    const removeIcon = (event) => {
        const icon = event.target.querySelector('i') || event.target;
        if (icon) {
            icon.style.opacity = '0';
            icon.classList.remove("fa-clipboard-check");
            icon.classList.add("fa-clipboard");
        }
    }

    return (
        <footer>
            <div className="footer-container">
                <div className="flex">
                    <div className="content">
                        {sites.map((site, index) => {
                            if (index === 0) {
                                return (
                                    <React.Fragment key={index}>
                                        <h2>{site.Heading}</h2>
                                        <div>
                                            <ul>
                                                {sites.map((siteLink, siteIndex) => (
                                                    <li key={siteIndex}>
                                                        <a href={siteLink.Sites}>{siteLink.Sites}</a>
                                                    </li>
                                                ))}
                                            </ul>
                                        </div>
                                    </React.Fragment>
                                );
                            }
                            return null;
                        })}
                    </div>
                    <div className="content">
                        {contact.map((contactInfo, index) => {
                            if (index === 0) {
                                return (
                                    <React.Fragment key={index}>
                                        <h2>{contactInfo.Heading}</h2>
                                        <p>{contactInfo.Address}</p>
                                        <div>
                                            <ul>
                                                {contact.map((contactLink, contactIndex) => (
                                                    <li key={contactIndex} onMouseOver={addIcon} onMouseOut={removeIcon} onClick={copyToClip} title="Copy to Clipboard">{contactLink.ContactLinks} <i className="fa-solid fa-clipboard"></i></li>
                                                ))}
                                            </ul>

                                        </div>
                                    </React.Fragment>
                                );
                            }
                            return null;
                        })}
                    </div>
                </div>
                {logo.map((logo, index) => (
                    <React.Fragment key={index}>
                        <div className="logo-container">
                            <Image host={"Shared"} imageName={logo.Logo} imageTitle={logo.Logo} />
                        </div>
                    </React.Fragment>
                ))}

            </div>
            <div className="copyright-container flex">
                {copyright.map((copyright, index) => (
                    <React.Fragment key={index}>
                        <div>
                            <p><strong>{copyright.Copyright} {new Date().getFullYear()}</strong></p>
                        </div>
                        <div>
                            <Pdf host={"Shared"} buttonText={copyright.Privacy} pdfName={copyright.PrivacyLink} className={"none"} />
                        </div>
                    </React.Fragment>
                ))}
            </div>
        </footer>
    );
}

export default Footer;