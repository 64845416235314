import { useEffect, useState } from "react";

import { Elements } from "@stripe/react-stripe-js";
import CheckoutForm from "./checkout-form";
import { loadStripe } from "@stripe/stripe-js";

import { Config } from "../../handleData";

function Payment({ language }) {
  const [stripePromise, setStripePromise] = useState(null);
  // const [clientSecret, setClientSecret] = useState("");

  useEffect(() => {
    Config().then(result => {
      const { publishableKey } = result;
      setStripePromise(loadStripe(publishableKey));
    });
  }, []);

  // useEffect(() => {
  //   Intent(total.total).then(result => {
  //     var { clientSecret } = result;
  //     setClientSecret(clientSecret);
  //   })
  // }, [total]);

  return (
    <main className="checkout-form-main">
      {stripePromise && language && (
        <Elements stripe={stripePromise} /*options={{ clientSecret }}*/>
          <CheckoutForm language={language}/>
        </Elements>
      )}
    </main>
  );
}

export default Payment;
