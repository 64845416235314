import React, { createContext, useContext, useEffect, useState } from 'react';
import CryptoJS from 'crypto-js';
import { getProductControls } from '../../handleData';
const CartContext = createContext();

export const useCart = () => {
  return useContext(CartContext);
};

export const CartProvider = ({ children }) => {
  // const [cart, setCart] = useState(() => {
  //   return JSON.parse(localStorage.getItem('cart'));
  // });
  // const [cartCounter, setCartCounter] = useState(() => {
  //   const cartCounterString = localStorage.getItem('cartCounter');
  //   return cartCounterString ? parseInt(cartCounterString, 10) : 0;
  // });
  const [cart, setCart] = useState([]);
  const [cartCounter, setCartCounter] = useState(0);
  const [shipping, setShipping] = useState([]);
  const [billing, setBilling] = useState([]);
  const [price, setPrice] = useState(null);
  const [orderLimit, setOrderLimit] = useState(null);


  const [headerHeight, setHeaderHeight] = useState(0);

  const encryptionKey = process.env.REACT_APP_ENCRYPTION_KEY;

  useEffect(() => {
    // Load cart data from localStorage on component mount
    const cartString = localStorage.getItem('cart');
    if (cartString !== null) {
      const decryptedCart = decrypt(cartString, encryptionKey);
      setCart(decryptedCart);
    }

    const shippingString = localStorage.getItem('shipping');
    if (shippingString !== null) {
      const decryptedShipping = decrypt(shippingString, encryptionKey);
      setShipping(decryptedShipping);
    }

    const billingString = localStorage.getItem('billing');
    if (billingString !== null) {
      const decryptedBilling = decrypt(billingString, encryptionKey);
      setShipping(decryptedBilling);
    }

    const cartCounterString = localStorage.getItem('cartCounter');
    if (cartCounterString !== null) {
      setCartCounter(parseInt(cartCounterString, 10));
    } else {
      setCartCounter(0);
    }

    const totalPriceString = localStorage.getItem('totalPrice');
    if (totalPriceString !== null) {
      const decryptedPrice = decrypt(totalPriceString, encryptionKey);
      setPrice(parseFloat(decryptedPrice).toFixed(2));
    }
  }, []);


  useEffect(() => {
    const encryptedCartString = encrypt(cart, encryptionKey);
    localStorage.setItem('cart', encryptedCartString);
  }, [cart]);

  useEffect(() => {
    const encryptedShippingString = encrypt(shipping, encryptionKey);
    localStorage.setItem('shipping', encryptedShippingString);
  }, [shipping]);

  useEffect(() => {
    const encryptedBillingString = encrypt(billing, encryptionKey);
    localStorage.setItem('billing', encryptedBillingString);
  }, [billing]);

  useEffect(() => {
    const encryptedPriceString = encrypt(price, encryptionKey);
    localStorage.setItem('totalPrice', encryptedPriceString);
  }, [price]);

  useEffect(() => {
    getProductControls().then(result => {
      setOrderLimit(result[0][0]["OrderLimit"]);
    })
  }, []);

  // useEffect(() => {
  //   // Load cart data from localStorage on component mount
  //   const cartString = localStorage.getItem('cart');
  //   setCart(cartString ? JSON.parse(cartString) : {});
  // }, []);


  const setShippingInfo = (name, phone, email, address, address2, city, country, province, postal, courier, shippingPrice, taxAmount, subTotal) => {
    const tempShipping = {
      info: {
        name: name,
        phone: phone,
        email: email,
        address: address,
        address2: address2,
        city: city,
        country: country,
        province: province,
        postal: postal,
      },
      fees: {
        courier: courier,
        shippingPrice: parseFloat(shippingPrice).toFixed(2),
        taxAmount: parseFloat(taxAmount).toFixed(2),
      },
      subTotal: subTotal
    }
    updateShipping(tempShipping);
  }

  const updateShipping = (shipping) => {
    if (shipping) {
      const encryptedShippingString = encrypt(shipping, encryptionKey);
      localStorage.setItem('shipping', encryptedShippingString);
      setShipping(shipping);
    }
  };

  const clearShipping = () => {
    localStorage.removeItem('shipping');
  }

  const setBillingInfo = (name, address, address2, city, country, province, postal) => {
    const tempBilling = {
      info: {
        name: name,
        address: address,
        address2: address2,
        city: city,
        country: country,
        province: province,
        postal: postal,
      }
    }
    updateBilling(tempBilling);
  }

  const updateBilling = (billing) => {
    if (billing) {
      const encryptedBillingString = encrypt(billing, encryptionKey);
      localStorage.setItem('billing', encryptedBillingString);
      setBilling(billing);
    }
  };

  const clearBilling = () => {
    localStorage.removeItem('billing');
  }

  const updateCartCounter = (newCounter) => {
    localStorage.setItem('cartCounter', newCounter.toString());
  }

  const setTotalPriceContext = (price) => {
    if (price > 0) {
      const tempPrice = price;
      updatePrice(tempPrice);
    }
  }

  const updatePrice = (newPrice) => {
    const encryptedPrice = encrypt(newPrice, encryptionKey);
    localStorage.setItem('totalPrice', encryptedPrice);
    setPrice(newPrice);
  }

  const clearPrice = () => {
    localStorage.removeItem("totalPrice");
  }

  // Function to add an item to the cart
  const addToCart = (product, stock) => {
    const { part } = product;
    const tempCart = { ...cart };
    const existingItem = tempCart[part];
    if (existingItem) {
      const newQuantity = existingItem.Quantity + 1;
      if (orderLimit && newQuantity <= orderLimit && newQuantity <= stock) {
        tempCart[part] = { ...product, Quantity: newQuantity, Stock: stock };
        updateCart(tempCart);
        return true;
      } else {
        return false;
      }
    } else {
      if (orderLimit && 1 <= stock) {
        tempCart[part] = { ...product, Quantity: 1, Stock: stock };
        const newCartCounter = cartCounter + 1;
        setCartCounter(newCartCounter);
        updateCartCounter(newCartCounter);
        updateCart(tempCart);
        return true;
      } else {
        return false;
      }

    }
  };

  // Function to update the cart in localStorage
  const updateCart = (cart) => {
    if (cart) {
      const encryptedCartString = encrypt(cart, encryptionKey);
      localStorage.setItem('cart', encryptedCartString);
      setCart(cart);
    }
  };

  // Function to remove an item from the cart
  const removeFromCart = (itemId) => {
    if (cart) {
      let newCartCounter = Object.keys(cart).length;
      newCartCounter = newCartCounter - 1;
      setCartCounter(newCartCounter);
      localStorage.setItem('cartCounter', newCartCounter.toString());
      delete cart[itemId];

      updateCart(cart);
    }
  };

  // Function to clear the cart
  const clearCart = () => {
    setCartCounter(0);
    localStorage.removeItem('cartCounter');
    localStorage.removeItem('cart');
    setCartCounter(0);
    if (cart) {
      Object.values(cart).forEach(item => {
        delete cart[item.part];
        updateCart(cart);
      });
    }

    // setCart({});
  };

  const addHeaderHeight = (height) => {
    setHeaderHeight(height);
  };

  // Function to encrypt data
  const encrypt = (data, key) => {
    const ciphertext = CryptoJS.AES.encrypt(JSON.stringify(data), key).toString();
    return ciphertext;
  };

  // Function to decrypt data
  const decrypt = (ciphertext, key) => {
    const bytes = CryptoJS.AES.decrypt(ciphertext, key);
    const decryptedData = JSON.parse(bytes.toString(CryptoJS.enc.Utf8));
    return decryptedData;
  };

  return (
    <CartContext.Provider value={{ headerHeight, cart, cartCounter, price, shipping, billing, setShippingInfo, updateShipping, clearShipping, setBillingInfo, updateBilling, clearBilling, setTotalPriceContext, clearPrice, updateCart, addToCart, removeFromCart, clearCart, addHeaderHeight }}>
      {children}
    </CartContext.Provider>
  );
};