import React, { useEffect, useState } from 'react';
import { getNotFound } from '../../handleData';
import { Link } from 'react-router-dom';

function NotFound({ language }) {
    const [notFound, setNotFound] = useState([]);
    const header = document.querySelector('header');
    if (header) {
        header.classList.add('cart-header');
    }

    useEffect(() => {
        if (language) {
            window.scrollTo(0, 0);
            getNotFound(language).then(result => {
                console.log(result)
                setNotFound(result);
            });
        }
    }, [language]);

    if (notFound) {
        console.log(notFound);
    }
    return (
        <main className='completion-container'>
            {notFound.map((notFound, index) => (
                <React.Fragment key={index}>
                    <section>
                        <h2>{notFound.Heading}</h2>
                        <p>{notFound.Text}</p>
                        <Link to={notFound.ButtonLink} title={notFound.ButtonText} className="btn">{notFound.ButtonText}</Link>
                    </section>
                </React.Fragment>
            ))}
        </main>
    );
}

export default NotFound;