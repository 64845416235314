import React, { useEffect, useState } from "react";
import { BrowserRouter, Routes, Route, Navigate } from "react-router-dom";
import { Helmet, HelmetProvider } from "react-helmet-async";

import { getColours, getDataCode, getHost, getLanguage, getMetaData, getProductControls } from './handleData';
// import { useCart } from "./views/stripe/cart-context";

import Header from "./views/content/header";
import Footer from "./views/content/footer";

import Shop from './views/content/shop';
import About from "./views/content/about";
import Contact from "./views/content/contact";
import Product from "./views/content/product";
import NotFound from "./views/content/notfound";

import Payment from "./views/stripe/payment";
import CartView from "./views/stripe/cart-view";
import Completion from "./views/stripe/completion";
import Fail from "./views/stripe/fail";

import './App.css';
import '../node_modules/@fortawesome/fontawesome-free/css/all.min.css';
import 'react-toastify/dist/ReactToastify.css';
import 'animate.css';

function App({ onAppLoaded }) {
  const [host, setHost] = useState(null);
  const [dataCode, setDataCode] = useState(null);
  const [metaData, setMetaData] = useState(null);
  const [language, setLanguage] = useState(null);
  const [colours, setColours] = useState(null);
  const [orderLimitArray, setOrderLimitArray] = useState([]);

  const root = document.documentElement;

  // const { clearCart } = useCart();

  useEffect(() => {
    getHost().then(result => {
      setHost(result);
    });

    getDataCode().then(result => {
      setDataCode(result);
    });

    getMetaData().then(result => {
      setMetaData(result);
    });

    getLanguage().then(result => {
      setLanguage(result);
    });

    getColours().then(result => {
      setColours(result);
    });

    getProductControls().then(result => {
      const newArray = [];
      for (let i = 1; i <= result[0][0]["OrderLimit"]; i++) {
        newArray.push(i);
      }
      setOrderLimitArray(newArray);
    }, []);

  }, []);

  const changeLanguage = (language, languageAbbr) => {
    const selectedLanguage = {
      language: language,
      languageAbbr: languageAbbr
    }

    setLanguage(selectedLanguage);
  }

  // useEffect(() => {
  //   if (host) {
  //     if (host !== prevHost) {
  //       setPrevHost(host);
  //     } else {
  //       clearCart();
  //     }
  //   }

  // }, [host, prevHost]);

  if (colours) {
    for (const key in colours) {
      root.style.setProperty(key, colours[key]);
    }
  }

  const [allComponentsRendered, setAllComponentsRendered] = useState(false);

  useEffect(() => {
    // Simulate an asynchronous operation to load child components
    const loadData = async () => {
      // Simulate some delay
      await new Promise(resolve => setTimeout(resolve, 2000));

      // Set the state to indicate that all child components have been rendered
      setAllComponentsRendered(true);
    };

    loadData().then(() => {
      onAppLoaded(); // Callback to signal that App has finished loading
    });
  }, [onAppLoaded]);

  return (
    <>
      {allComponentsRendered ? (
        <HelmetProvider>
          {metaData && Object.keys(metaData).length > 0 &&
            <Helmet>
              <title>{metaData.title}</title>
              <meta property="og:type" content="website" />
              <meta name="description" content={metaData.metaDescription} />
              <meta property="og:title" content={metaData.title} />
              <meta property="og:description" content={metaData.metaDescription} />
              <meta property="og:url" content={metaData.url} />
              <link rel="canonical" href="/shop"/>
            </Helmet>
          }

          <BrowserRouter>
            <Header language={language} changeLanguage={changeLanguage} orderLimitArray={orderLimitArray} />
            <Routes>
              <Route
                path="/"
                element={
                  <Navigate to="/shop" replace /> // Redirect to /shop
                }
              />
              <Route path="/shop" element={<Shop host={host} language={language} dataCode={dataCode} />} />
              <Route path="/about" element={<About host={host} language={language} />} />
              <Route path="/contact" element={<Contact host={host} language={language} />} />

              <Route path="/product/:partNumber" element={<Product host={host} language={language} />} />
              <Route path="/cart" element={<CartView language={language} orderLimitArray={orderLimitArray} />} />
              <Route path="/checkout" element={<Payment language={language} />} />
              <Route path="/completion/" element={<Completion language={language} />} />
              <Route path="/fail/" element={<Fail language={language} />} />

              <Route path="*" element={<NotFound language={language} />} />
            </Routes>

            <Footer language={language} />
          </BrowserRouter>
        </HelmetProvider>
      ) : (
        <div className="loading-container flex">
          <div className="animate__animated animate__backInUp">
            <i className="fa-solid fa-spinner fa-spin"></i>
            <h2>Loading <strong>{window.location.host}</strong></h2>
          </div>
        </div>
      )}
    </>
  );
}

export default App;
