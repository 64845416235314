import React, { useEffect, useRef, useState } from "react";
import { Link } from "react-router-dom";

import Image from "../components/image";
import { getAbout } from "../../handleData";
import Widget from "../components/widget";

function About({ host, language }) {
    const [about, setAbout] = useState([]);
    const aboutSection = useRef(null);

    useEffect(() => {
        setTimeout(() => {
            if (aboutSection.current) {
                aboutSection.current.scrollIntoView({
                    behavior: 'smooth'
                });
            }
        }, 0);

    }, [aboutSection.current]);

    useEffect(() => {
        if (host && language) {
            getAbout(host, language).then(result => {
                setAbout(result[0]);
            });
        }
    }, [host, language]);
    return (
        <main>
            <Widget type={"masthead"} host={host} language={language}/>
            <section ref={aboutSection} className="content about-content flex">
                {about.map((about, index) => (
                    <React.Fragment key={index}>
                        <div className="about-content-container">
                            <h2 id="about-section">{about.Heading}</h2>
                            <p>{about.Text}</p>
                            <p>{about.Text2}</p>
                            <p>{about.Text3}</p>
                            <Link to={'/contact'} className="btn">{about.ButtonText}</Link>
                        </div>
                        <div className="welcome-img">
                            <Image host={host} imageName={about.Image} imageTitle={about.Heading} />
                        </div>
                    </React.Fragment>
                ))}
            </section>
            <Widget type={'contact'} language={language}/>
        </main>
    );
}

export default About;