import React, { useEffect, useState } from "react";
import { getPdfs } from "../../handleData";


function Pdf({ host, pdfName, buttonText, className }) {
    const [pdfPath, setPdfPath] = useState('');
    const [pdfElement, setPdfElement] = useState(null);

    useEffect(() => {
        if (pdfName.startsWith('http')) {
            setPdfPath(pdfName);
        } else if (pdfName === '') {
            setPdfPath(false);
        } else {
            getPdfs(host, pdfName).then(result => {
                setPdfPath(result);
            });
        }
    }, [pdfName, host]);

    useEffect(() => {
        if (!pdfPath) {
            setPdfElement(<button className={`${className} disabled`} disabled>{buttonText}</button>)
        } else {
            setPdfElement(<a href={pdfPath} target="_blank" rel="noreferrer" download={pdfName} className={className}>{buttonText}</a>)
        }
    }, [pdfPath]);

    return (
        pdfElement
    )
}

export default Pdf;