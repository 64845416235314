import React, { useEffect, useState } from "react";
import { useCart } from "./cart-context";
import { useNavigate, useLocation, Link } from "react-router-dom";
import { getCheckoutPage } from "../../handleData";

function Completion({ language }) {
  const [orderConfirm, setOrderConfirm] = useState([]);
  const [noOrder, setNoOrder] = useState([]);

  const { clearCart, cart } = useCart();
  const navigate = useNavigate();
  const location = useLocation();

  const queryParams = new URLSearchParams(location.search);
  const encodedIsSuccess = queryParams.get('success');
  const decodedIsSuccess = JSON.parse(decodeURIComponent(encodedIsSuccess));

  useEffect(() => {
    if (decodedIsSuccess === null || decodedIsSuccess.boolean === false) {
      navigate('/cart');
    } else {
      if (cart && Object.keys(cart).length > 0) {
        clearCart();
      }
    }

  }, [cart, clearCart, decodedIsSuccess, navigate]);

  useEffect(() => {
    if (language) {
      getCheckoutPage(language).then(result => {
        setOrderConfirm(result[0]);
        setNoOrder(result[1]);
      });
    }
  }, [language]);

  return (
    <main className="completion-container">
      {decodedIsSuccess ? (
        <section>
          {orderConfirm.map((checkout, index) => (
            <React.Fragment key={index}>
              <h1>{checkout.Heading} "<strong>{decodedIsSuccess.orderNumber}</strong>"</h1>
              <h2>{checkout.Heading2}</h2>
              <p>{checkout.Text}</p>
              <p>{checkout.Text2} <a href={`mailto:${checkout.SupportEmail}`}>{checkout.SupportEmail}</a></p>
              <Link to={checkout.ButtonLink} title={checkout.ButtonText} className="btn">{checkout.ButtonText}</Link>
            </React.Fragment>
          ))}
        </section>
      ) : (
        <section>
          {noOrder.map((noOrder, index) => (
            <React.Fragment key={index}>
              <h1>{noOrder.Heading}</h1>
              <p>{noOrder.Text}</p>
              <Link to={noOrder.ButtonLink} title={noOrder.ButtonText} className="btn">{noOrder.ButtonText}</Link>
            </React.Fragment>
          ))}
        </section>
      )}
    </main>
  );
}

export default Completion;
