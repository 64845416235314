import React, { useEffect, useState, useRef } from "react";
import { useParams, Link, useLocation } from "react-router-dom";
import { toast, ToastContainer } from 'react-toastify';

import Image from "../components/image";
import Pdf from "../components/pdf";
import { getSingleProduct, getStock, getShop, sendContact, getProductContent, getWidgets, getAlerts, setUserInfo } from "../../handleData";
import { useCart } from "../stripe/cart-context";
import Widget from "../components/widget";

function Product({ host, language }) {
    let { partNumber } = useParams();

    const [product, setProduct] = useState([]);
    const [productContent, setProductContent] = useState(null);
    const [related, setRelated] = useState([]);
    const [noStock, setNoStock] = useState([]);
    const [formValues, setFormValues] = useState([]);
    const [alerts, setAlerts] = useState(null);

    const [name, setName] = useState('');
    const [phone, setPhone] = useState('');
    const [email, setEmail] = useState('');
    const [part, setPart] = useState('');

    const [stock, setStock] = useState(0);

    const productElement = useRef(null);
    const form = useRef(null);

    const { addToCart } = useCart();

    const location = useLocation();

    useEffect(() => {
        if (location.hash) {
            setTimeout(() => {
                if (productElement.current) {
                    productElement.current.scrollIntoView({
                        behavior: 'smooth'
                    });
                }
            }, 0);
        } else {
            window.scrollTo(0, 0);
        }

    }, [location, productElement.current]);

    useEffect(() => {
        if (product && Object.keys(product).length > 0) {
            const userInfo = {
                origin: window.location.href,
                product: product
            }
            setUserInfo(userInfo);
        }

    }, [product]);

    useEffect(() => {
        if (partNumber) {
            // window.scrollTo(0, 0);
            getSingleProduct(partNumber).then(result => {
                setProduct(result.singleProduct);
                setRelated(result.relatedProducts);
            })
        }
    }, [partNumber]);

    useEffect(() => {
        if (host && language) {
            getShop(host, language).then(result => {
                setNoStock(result[1]);
            });

            getProductContent(language).then(result => {
                setProductContent(result[0][0]);
            });
        }
    }, [host, language]);

    useEffect(() => {
        if (product && Object.keys(product).length > 0) {
            getStock(product.part).then(result => {
                let requestId = result.requestId;

                if (result.stockResult[requestId].GETSTOCK <= 0) {
                    setStock(0);
                } else {
                    const parsedStock = parseInt(result.stockResult[requestId].GETSTOCK);
                    setStock(parsedStock);
                }
            });
        }

    }, [product]);

    useEffect(() => {
        if (language) {
            getWidgets("form", language).then(result => {
                setFormValues(result[0]);
            });
        }
    }, [language]);

    useEffect(() => {
        if (language) {
            getAlerts(language).then(result => {
                setAlerts(result[0][0]);
            });
        }
    }, [language]);


    const addToCartBtn = async (partNumber) => {
        if (alerts) {
            getStock(partNumber).then(result => {
                let requestId = result.requestId;
                if (result.stockResult[requestId].GETSTOCK <= 0) {
                    setStock(0);
                    toast.error(alerts.ErrorMessage, {
                        position: toast.POSITION.TOP_LEFT,
                    });
                } else {
                    const parsedStock = parseInt(result.stockResult[requestId].GETSTOCK);
                    setStock(parsedStock);
                    const isAdded = addToCart(product, parsedStock);
                    if (isAdded) {
                        const widget = document.querySelector('.cart-widget');
                        widget.classList.add('show-cart');
                        toast.success(alerts.AddToCart, {
                            position: toast.POSITION.TOP_LEFT,
                        });
                    } else {
                        toast.error(alerts.AddToCartFail, {
                            position: toast.POSITION.TOP_LEFT,
                        });
                    }
                }
            });
        }
    }

    const handleSubmit = async (event) => {
        event.preventDefault();
        sendContact("product", name, email, phone, null, part).then(result => {
            if (result === true) {
                toast.success("Email Sent Successfully!");
                form.current.reset();
            } else {
                toast.error("Email Failed to send!");
            }
        });

    };

    return (
        <main>
            <ToastContainer />
            <Widget type={'masthead'} host={host} language={language} />
            <section ref={productElement} className="content" id="product">
                {product && Object.keys(product).length > 0 &&
                    <>
                        <h2>{product.part}</h2>
                        <div className="flex product-container">
                            <div className="product-image">
                                <Image host={host} imageName={product.image} imageTitle={product.part} />
                            </div>

                            {productContent && stock > 0 && product.price > 0 && product.weight > 0 ? (
                                <div className="product-content flex">
                                    <div className="product-details">
                                        <p><small>{product.subcat.substring(2)}</small></p>
                                        <h3>{product.title}</h3>
                                        {product.price > 0 ? (
                                            <h3 id="price">${product.price.toFixed(2)}</h3>
                                        ) : (
                                            <h3 id="price">{productContent.ContactPrice}</h3>
                                        )}
                                        <p>{product.desc}</p>

                                        {product.price > 0 ? (
                                            <div className="buttons-stock-container flex">
                                                <div className='product-buttons-container flex'>
                                                    <div>
                                                        <Link to={"/shop"} title="Back to Shop" className="button"><i className="fa-solid fa-chevron-left"></i></Link>
                                                    </div>
                                                    <div>
                                                        <Pdf host={host} pdfName={product.pdf} buttonText={"PDF"} className="button" />
                                                    </div>
                                                    <div>
                                                        <button className="button" id="cart-btn" onClick={() => addToCartBtn(product.part)} title={productContent.AddToCart}>{productContent.AddToCart} <i className="fa-solid fa-cart-plus"></i></button>
                                                    </div>

                                                    <div className="stock-container">
                                                        <p>{productContent.Stock}: {stock}</p>
                                                    </div>
                                                </div>

                                            </div>
                                        ) : (
                                            <h3 id="price">{productContent.ContactPrice}</h3>
                                        )}
                                    </div>
                                </div>
                            ) : (
                                <div className="product-content flex">
                                    <div className="product-details">
                                        <p><small>{product.subcat.substring(2)}</small></p>
                                        <h3>{product.title}</h3>
                                        {product.price > 0 ? (
                                            <h3 id="price">${product.price.toFixed(2)}</h3>
                                        ) : (
                                            <h3 id="price">{productContent.ContactPrice}</h3>
                                        )}
                                        <h3>{product.desc}</h3>
                                    </div>
                                    <div className="out-of-stock-container">
                                        <div>
                                            {noStock.map((content, index) => (
                                                <React.Fragment key={index}>
                                                    <h3><i className="fa-solid fa-circle-info"></i> {content.Heading}</h3>
                                                    <p>{content.Text}</p>
                                                </React.Fragment>
                                            ))}
                                        </div>
                                        <div className="cart-summary-card">
                                            <form className="shipping-form" onSubmit={handleSubmit} ref={form}>
                                                {formValues &&
                                                    <>
                                                        <div className="flex">
                                                            <input type='text' name='name' id='name' placeholder={formValues.Name} onChange={(event) => setName(event.target.value)} required />

                                                            <input type='tel' name='phone' id='phone' placeholder={formValues.Phone} onChange={(event) => setPhone(event.target.value)} required />
                                                        </div>
                                                        <div className="flex">
                                                            <input type='email' name='email' id='email' placeholder={formValues.Email} onChange={(event) => setEmail(event.target.value)} required />
                                                        </div>
                                                        <div className="flex">
                                                            <input type='text' name='part' id='part' placeholder='Part Number' value={product.part} onChange={(event) => setPart(event.target.value)} required />
                                                        </div>
                                                        <div className="checkout-form-buttons flex">
                                                            <div>
                                                                <Link to={"/shop"} title="Back to Shop"><i className="fa-solid fa-chevron-left"></i></Link>
                                                            </div>
                                                            <div>
                                                                <Pdf host={host} pdfName={product.pdf} buttonText={"PDF"} />
                                                            </div>
                                                            <button className='button' id='submit'>Send <i className="fa-solid fa-share"></i></button>
                                                        </div>
                                                    </>
                                                }
                                            </form>
                                        </div>
                                    </div>
                                </div>

                            )}
                        </div>
                    </>
                }
            </section>
            <section className="content related" id="shop">
                <div className="categories-title">
                    {productContent &&
                        <h2>{productContent.Heading}</h2>
                    }
                </div>
                <div className="card-grid-container">
                    {productContent && related.map((product, index) => (
                        <React.Fragment key={index}>
                            <Link to={`/product/${product.part}#product`} className="card">
                                <div className="card-image">
                                    <Image imageName={product.image} imageTitle={product.desc} />
                                </div>
                                <div className="card-content">
                                    <div>
                                        <p><small>{product.subcat.substring(2)}</small></p>
                                        <h4>{product.title}</h4>
                                    </div>
                                    <div>
                                        {product.price > 0 ? (
                                            <h4><strong>${product.price.toFixed(2)}</strong></h4>
                                        ) : (
                                            <h4><strong>{productContent.ContactPrice}</strong></h4>
                                        )}
                                    </div>
                                </div>
                            </Link>
                        </React.Fragment>
                    ))}
                </div>
            </section>
            <Widget type={'contact'} language={language} />
        </main>
    );

}

export default Product;