// backend url
let backendUrl = '';

if (window.location.hostname === 'localhost') {
    backendUrl = `${window.location.protocol}//${window.location.hostname}:3001`;
} else {
    backendUrl = `${window.location.protocol}//${window.location.host}/backend`;
}

export async function getBackendUrl() {
    return backendUrl;
}

export async function getHost() {
    let host;

    const response = await fetch(`${backendUrl}/shared/domains`);
    const data = await response.json();
    for (const domain of data) {
        if (window.location.hostname.includes(domain.Host)) {
            host = domain.Folder;
            break;
        }
    }
    return host;
}

export async function getDataCode() {
    let dataCode;

    const response = await fetch(`${backendUrl}/shared/domains`);
    const data = await response.json();
    for (const domain of data) {
        if (window.location.hostname.includes(domain.Host)) {
            dataCode = domain.DataCode;
            break;
        }
    }
    return dataCode;
}

export async function getMetaData() {
    let metaData = {};
    let title;
    let metaDescription;

    const response = await fetch(`${backendUrl}/shared/domains`);
    const data = await response.json();
    for (const domain of data) {
        if (window.location.hostname.includes(domain.Host)) {
            title = domain.Title;
            metaDescription = domain.MetaDescription;
            const ogUrl = `${window.location.protocol}//${window.location.host}`;
            metaData = {
                title: title,
                metaDescription: metaDescription,
                url: ogUrl
            }
            break;
        }
    }
    return metaData;
}

export async function getLanguage() {
    let language;
    let languageAbbr;
    const userLang = navigator.language.toLowerCase();

    const response = await fetch(`${backendUrl}/shared/languages`);
    const data = await response.json();

    for (const lang of data) {
        if (userLang.startsWith(lang.LanguageAbbr)) {
            language = lang.Language;
            languageAbbr = lang.LanguageAbbr;
            break;
        } else {
            language = 'English';
            languageAbbr = 'en';
        }
    }
    return { languageAbbr, language };
}
export async function getLanguages() {
    const response = await fetch(`${backendUrl}/shared/languages`);
    const data = await response.json();
    return data;
}

export async function getShippingAdmin() {
    const response = await fetch(`${backendUrl}/shared/shipping-admin`);
    const data = await response.json();
    return data;
}

export async function getColours() {
    let colours = {};

    const response = await fetch(`${backendUrl}/shared/domains`);
    const data = await response.json();
    for (const domain of data) {
        if (window.location.hostname.includes(domain.Host)) {
            colours = {
                "--light": domain.LightColour,
                "--grey": domain.GreyColour,
                "--dark": domain.DarkColour,
                "--main": domain.MainColour,
                "--accent-colour": domain.AccentColour,
            }
            break;
        }
    }
    return colours;
}

export async function getProducts(dataCode) {
    const response = await fetch(`${backendUrl}/products/${dataCode}`);
    const data = await response.json();
    return data;
}

export async function getStock(partNumber) {
    const response = await fetch(`${backendUrl}/products/get-stock/${partNumber}`);
    const data = response.json();
    return data;
}
export async function getProductControls() {
    const response = await fetch(`${backendUrl}/shared/product-controls`);
    const data = response.json();
    return data;
}

export async function getTax(province) {
    const response = await fetch(`${backendUrl}/products/get-tax/${province}`);
    const data = response.json();
    return data;
}

export async function getHeader(language) {
    const response = await fetch(`${backendUrl}/shared/${language.language}/header`);
    const data = await response.json();
    return data;
}

export async function getFooter(language) {
    const response = await fetch(`${backendUrl}/shared/${language.language}/footer`);
    const data = await response.json();
    return data;
}

export async function getShop(host, language) {
    const response = await fetch(`${backendUrl}/content/${host}/${language.language}/shop`);
    const data = await response.json();
    return data;
}

export async function getAbout(host, language) {
    const response = await fetch(`${backendUrl}/content/${host}/${language.language}/about`);
    const data = await response.json();
    return data;
}

export async function getContact(language) {
    const response = await fetch(`${backendUrl}/contact/${language.language}/contact-page`);
    const data = await response.json();
    return data;
}

export async function getWidgets(type, language) {
    const response = await fetch(`${backendUrl}/content/widgets/${type}/${language.language}`);
    const data = await response.json();
    return data;
}

export async function getMasthead(host, language) {
    const response = await fetch(`${backendUrl}/content/${host}/${language.language}/masthead`);
    const data = await response.json();
    return data;
}
export async function getCheckoutPage(language) {
    const response = await fetch(`${backendUrl}/shared/checkout-page/${language.language}`);
    const data = await response.json();
    return data;
}

export async function getCartPage(language) {
    const response = await fetch(`${backendUrl}/shared/cart-page/${language.language}`);
    const data = await response.json();
    return data;
}

export async function getCheckoutForm(language) {
    const response = await fetch(`${backendUrl}/shared/checkout-form/${language.language}`);
    const data = await response.json();
    return data;
}

export async function getAlerts(language) {
    const response = await fetch(`${backendUrl}/shared/alerts/${language.language}`);
    const data = await response.json();
    return data;
}

export async function getNotFound(language) {
    const response = await fetch(`${backendUrl}/shared/notfound/${language.language}`);
    const data = await response.json();
    return data;
}


export async function getErrorPage(language) {
    const response = await fetch(`${backendUrl}/shared/error-page/${language.language}`);
    const data = await response.json();
    return data;
}


// export async function getShop() {
//     const response = await fetch(`${backendUrl}/shop`);
//     const data = await response.json();

//     const response2 = await fetch(`${backendUrl}/shop/category-data`);
//     const data2 = await response2.json();
//     const shopData = {
//         Page: data,
//         Categories: data2
//     };

//     return shopData;
// }

export async function getProductContent(language) {
    const response = await fetch(`${backendUrl}/shared/product-content/${language.language}`);
    const data = await response.json();
    return data;
}

export async function getSingleProduct(partNumber) {
    let newProductStates = {};
    try {
        const dataCode = await getDataCode();
        const products = await getProducts(dataCode);
        // Loop through each product in the productsData array
        products[0].forEach((product, index) => {
            // Create a unique key for each product state based on its index
            const productKey = `${product.part}`;

            // Add the product state to the newProductStates object
            newProductStates[productKey] = product;
        });


        let singleProduct;
        Object.values(newProductStates).forEach(product => {
            if (product.part.toString() !== partNumber) {
                return;
            }
            singleProduct = product;
        });

        const relatedProducts = [];
        const sameCatProducts = products[0].filter(item => item.subcat === singleProduct.subcat);
        const productIds = [];

        sameCatProducts.forEach((product, index) => {
            if (index < 3) {
                const randomIndex = Math.floor(Math.random() * sameCatProducts.length);
                const randomProduct = sameCatProducts[randomIndex];

                if (!productIds.includes(randomIndex) && randomProduct.part !== partNumber) {
                    productIds.push(randomIndex);
                    relatedProducts.push(randomProduct);
                }
            }
        });

        while (relatedProducts.length < 3) {
            const randomIndex = Math.floor(Math.random() * products[0].length);
            const randomProduct = products[0][randomIndex];

            if (!productIds.includes(randomIndex) && randomProduct.part !== partNumber) {
                productIds.push(randomIndex);
                relatedProducts.push(randomProduct);
            }
        }

        // while (relatedProducts.length < 3) {
        //     const randomIndex = Math.floor(Math.random() * sameCatProducts.length);
        //     const randomProduct = sameCatProducts[randomIndex];

        //     if (!productIds.includes(randomIndex) && randomProduct.part !== partNumber) {
        //         productIds.push(randomIndex);
        //         relatedProducts.push(randomProduct);
        //     }

        // }

        return {
            singleProduct: singleProduct,
            relatedProducts: relatedProducts
        };
    } catch (error) {
        // Handle any errors that occur during fetching or processing data
        console.error("Error fetching data:", error);
    }
}

export async function setUserInfo(userInfo) {
    try {
        const response = await fetch(`${backendUrl}/set-info`, {
            method: 'POST',
            headers: {
                "Content-Type": 'application/json'
            },
            body: JSON.stringify({ userInfo }),
            mode: "cors"
        });
        
        if (response.ok) {
            return true;
        } else {
            throw new Error('Request failed');
        }

    } catch (error) {
        console.error(error);
    }
}

export async function sendContact(type, name, email, phone, message, part) {
    try {
        const response = await fetch(`${backendUrl}/contact/contact-us`, {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
            },
            body: JSON.stringify({ type, name, email, phone, message, part }),
            mode: 'cors'
        });
        if (response.ok) {
            return true;
        } else {
            throw new Error('Request failed');
        }
    } catch (error) {
        console.error(error);
    }
}


//get images & pdfs
export async function getImages(host, imageName) {
    const response = await fetch(`${backendUrl}/assets/${host}/images/${imageName}`);
    const blob = await response.blob();
    return URL.createObjectURL(blob);
}

export async function getPdfs(host, pdfName) {
    const response = await fetch(`${backendUrl}/assets/${host}/pdfs/${pdfName}`);
    const blob = await response.blob();
    return URL.createObjectURL(blob);
}

// stripe payment functions
export async function Config() {
    const response = await fetch(`${backendUrl}/payment/config`);
    const data = await response.json();
    return data;
}

export async function Intent(total) {
    const response = await fetch(`${backendUrl}/payment/create-payment-intent/${total}`, {
        method: "POST",
        body: JSON.stringify({}),
    });

    const data = await response.json();
    return data;
}

export async function sendOrderInfo(orderInfo) {
    try {
        fetch(`${backendUrl}/shipping/set-info`, {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
            },
            body: JSON.stringify({ orderInfo }),
            mode: 'cors'
        });
    } catch (error) {
        console.error(error);
    }
}

export async function getShippingInfo() {
    const response = await fetch(`${backendUrl}/shipping/info`);
    const data = await response.json();
    return data;
}

export async function sendOrder(shippingInfo, cart) {
    try {

        const response = await fetch(`${backendUrl}/shipping/send-order`, {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
            },
            body: JSON.stringify({ shippingInfo, cart }),
            mode: 'cors'
        });
        if (response.ok) {
            return true;
        } else {
            throw new Error('Request failed');
        }
    } catch (error) {
        console.error(error);
    }
}
