import React, { useState, useEffect, useRef } from "react";
import { Link, useNavigate } from "react-router-dom";

// import { PaymentElement } from "@stripe/react-stripe-js";
import { CardElement, useStripe, useElements } from "@stripe/react-stripe-js";

import Image from "../components/image";
import { getBackendUrl, getCartPage, getCheckoutForm, getWidgets } from "../../handleData";
import { useCart } from './cart-context';

function CheckoutForm({ language }) {
  const stripe = useStripe();
  const elements = useElements();

  const [checkoutForm, setCheckoutForm] = useState(null);
  const [formValues, setFormValues] = useState([]);
  const [summary, setSummary] = useState(null);
  const [items, setItems] = useState([]);

  const [isProcessing, setIsProcessing] = useState(false);

  const [serverUrl, setServerUrl] = useState('');

  const { cart, price, shipping, setBillingInfo, billing } = useCart();
  // const serializedCart = encodeURIComponent(JSON.stringify(cart));

  const [useShipping, setUseShipping] = useState(true);
  const [isChecked, setIsChecked] = useState(true);

  const [name, setName] = useState('');
  const [address, setAddress] = useState('');
  const [address2, setAddress2] = useState('');
  const [country, setCountry] = useState('CA');
  const [province, setProvince] = useState('');
  const [fullProvince, setFullProvince] = useState('');
  const [city, setCity] = useState('');
  const [postal, setPostal] = useState('');

  const form = useRef(null);
  const cartView = useRef(null);
  const button = useRef(null);
  const navigate = useNavigate();

  const [provincesFull] = useState(() => {
    return ["Alberta",
      "British Columbia",
      "Manitoba",
      "New Brunswick",
      "Newfoundland and Labrador",
      "Northwest Territories",
      "Nova Scotia",
      "Nunavut",
      "Ontario",
      "Prince Edward Island",
      "Quebec",
      "Saskatchewan",
      "Yukon",
    ];
  })

  const [provincesAbbreviation] = useState(() => {
    return ["AB",
      "BC",
      "MB",
      "NB",
      "NL",
      "NT",
      "NS",
      "NU",
      "ON",
      "PE",
      "QC",
      "SK",
      "YT",
    ];
  });

  // useEffect(() => {
  //   if (window.location.host.includes('localhost')) {
  //     setIsLive(false);
  //   } else {
  //     setIsLive(false);
  //   }
  // }, []);

  useEffect(() => {
    setItems(cart);
  }, [cart]);

  useEffect(() => {
    if (!price) {
      navigate('/cart');
    }
  }, [price, navigate]);

  useEffect(() => {
    window.scrollTo(0, 0);
    getBackendUrl().then(result => {
      setServerUrl(result);
    });
  }, []);

  useEffect(() => {
    if (language) {
      getCheckoutForm(language).then(result => {
        setCheckoutForm(result);
      });

      getCartPage(language).then(result => {
        setSummary(result);
      });
    }
  }, [language]);

  useEffect(() => {
    if (language) {
      getWidgets("form", language).then(result => {
        setFormValues(result[0]);
      });
    }
  }, [language]);

  useEffect(() => {
    const convertToAbbreviation = (abbriProvince) => {

      const index = provincesAbbreviation.indexOf(abbriProvince);

      if (index !== -1) {
        return provincesFull[index];
      }

      return "Invalid Province"; // Return a default value for invalid inputs
    }

    if (useShipping && shipping && Object.keys(shipping).length > 0) {
      setName(shipping.info.name);
      setAddress(shipping.info.address);
      setAddress2(shipping.info.address2);
      setProvince(shipping.info.province);
      setCity(shipping.info.city);
      setPostal(shipping.info.postal);
      setFullProvince(convertToAbbreviation(shipping.info.province))
    } else {
      setName("");
      setAddress("");
      setAddress2("");
      setProvince("");
      setCity("");
      setPostal("");
      setFullProvince("");
    }
  }, [shipping, useShipping, provincesFull, provincesAbbreviation]);

  useEffect(() => {
    if (name && address && city && province && postal) {
      setBillingInfo(name, address, address2, city, country, province, postal)
    }
  }, [name, address, city, country, province, postal]);

  const checkChange = (event) => {
    const checked = event.target.checked;
    setIsChecked(checked);

    // Set Variable to false if the checkbox is unchecked
    if (!checked) {
      setUseShipping(false);
    } else {
      setUseShipping(true);
    }
  }

  const handleSubmit = async (event) => {
    event.preventDefault();
    const result = await stripe.createPaymentMethod({
      type: 'card',
      card: elements.getElement(CardElement),
      billing_details: {
        name: name,
        address: {
          line1: address,
          line2: address2,
          city: city,
          state: province,
          postal_code: postal.toUpperCase(),
          country: 'CA',
        },
      },
    });
    setIsProcessing(true);
    button.current.disabled = true;

    if (result.error) {
      setIsProcessing(false);
      button.current.disabled = false;
    } else {
      // Send the payment method ID to your server for further processing.
      setCountry('CA');
      setIsProcessing(true);
      button.current.disabled = true;
      // const serializedShipping = encodeURIComponent(JSON.stringify(shipping));
      // const serializedBilling = encodeURIComponent(JSON.stringify(billing));
      // const serializedPaymentMethod = encodeURIComponent(JSON.stringify(result.paymentMethod));
      // const serializedURL = encodeURIComponent(JSON.stringify(`${window.location.protocol}//${window.location.host}`));
      const dataToSend = {
        cart: cart,
        shipping: shipping,
        billing: billing,
        total: price,
        paymentMethod: result.paymentMethod,
        origin: `${window.location.host}`
      }
      const response = await fetch(`${serverUrl}/shipping/send-order`, {
        "method": "POST",
        "headers": {
          "Content-Type": "application/json; charset=utf-8"
        },
        "body": JSON.stringify(dataToSend),
        "cors": "cors"
      });
      const serializedData = await response.json();
      const data = JSON.parse(decodeURIComponent(serializedData));

      if (response.ok) {
        if (data.boolean && data.orderNumber) {
          navigate(`/completion/?success=${serializedData}`);
        } else {
          navigate(`/fail/?success=${serializedData}`);
        }
      } else {
        navigate(`/fail/?success=${serializedData}`);
      }

      // window.location.href = `${serverUrl}/shipping/send-order/?cart=${serializedCart}&shipping=${serializedShipping}&billing=${serializedBilling}&price=${price}&paymentMethod=${serializedPaymentMethod}&origin=${serializedURL}`;

      // fetch(`${serverUrl}/shipping/send-order`, {
      //   method: 'POST',
      //   headers: {
      //     'Content-Type': 'application/json',
      //   },
      //   body: JSON.stringify({ cart, shipping, billing, price, paymentMethod, origin }),
      //   mode: 'cors'
      // });
    }
    // ?cart=${serializedCart}&shipping=${serializedShipping}&billing=${serializedBilling}&price=${price}&paymentMethod=${serializedPaymentMethod}&origin=${serializedURL}
  };

  const cardElementOptions = {
    hidePostalCode: true,
    style: {
      base: {
        fontSize: "16px",
        color: '#333',
        boxShadow: "0px 0px 5px #abb8c3",
        padding: '1rem'
      },
      invalid: {
        color: '#9e2146',
      },
    },
  };

  useEffect(() => {
    setTimeout(() => {
      if (form.current) {
        const elementHeight = form.current.offsetHeight;
        cartView.current.style.minHeight = `${elementHeight}px`
      }
    }, 100)

  }, []);

  const stringLength = 20;

  const truncate = (string, length) => {
    if (string.length > length) {
      return string.slice(0, length) + '...';
    } else {
      return string;
    }
  };

  return (
    <section className="content">
      {checkoutForm &&
        <>
          <h2>{checkoutForm[0].Heading}</h2>
          <p>{checkoutForm[0].Text}</p>
        </>
      }
      <div className="checkout-form flex">
        <form id="payment-form" className="shipping-form payment-form" ref={form} onSubmit={handleSubmit}>
          {/* <PaymentElement /> */}
          <div className="card-element-container">
            <CardElement options={cardElementOptions} />
          </div>

          <div className="flex">
            {checkoutForm &&
              <p>{checkoutForm[0].BillingAddress}</p>
            }
            <input type="checkbox" checked={isChecked} onChange={checkChange} />
          </div>
          {formValues &&
            <>
              <div className="flex">
                <input type='text' name='name' id='name' placeholder={formValues.Name} onChange={(event) => setName(event.target.value)} value={useShipping ? name : name} disabled={useShipping ? 'disabled' : ''} required />
              </div>

              <div className="flex">
                <input type='text' name='address' id='address' placeholder={formValues.Address} onChange={(event) => setAddress(event.target.value)} value={useShipping ? address : address} disabled={useShipping ? 'disabled' : ''} required />
                <input type='text' name='address2' id='address2' placeholder={formValues.Address2} onChange={(event) => setAddress2(event.target.value)} value={useShipping ? address2 : address2} disabled={useShipping ? 'disabled' : ''} />
              </div>

              <div className="flex">
                <input type='text' name='city' id='city' placeholder={formValues.City} onChange={(event) => setCity(event.target.value)} value={useShipping ? city : city} disabled={useShipping ? 'disabled' : ''} required />
                <input type='text' name='postal' id='postal' pattern='[A-Za-z][0-9][A-Za-z][0-9][A-Za-z][0-9]' placeholder={formValues.PostalCode} value={useShipping ? postal : postal} disabled={useShipping ? 'disabled' : ''} onKeyDown={(event) => {
                  const key = event.key;
                  if (!(/[a-zA-Z0-9]/.test(key))) {
                    event.preventDefault();
                  }
                  if (key === ' ') {
                    event.preventDefault();
                  }
                }}
                  onPaste={(event) => {
                    event.preventDefault(); // Prevent the default paste behavior
                    const clipboardData = event.clipboardData;
                    const pastedText = clipboardData.getData('text');
                    const newValue = pastedText.replace(/\s/g, ''); // Remove all spaces from the pasted text
                    // Update the state with the new value
                    setPostal(newValue.toUpperCase());
                  }}
                  onInput={(event) => {
                    const newValue = event.target.value.replace(/\s/g, '');
                    setPostal(newValue.toUpperCase());
                    if (newValue) {
                      event.target.maxLength = 6;
                    } else {
                      event.target.maxLength = 7;
                    }
                  }}
                  style={{ textTransform: 'uppercase' }} maxLength={7} required />
              </div>

              <div className="flex">
                <select name='province' id="province" value={province} onChange={(event) => setProvince(event.target.value)} disabled={useShipping ? 'disabled' : ''} required>
                  {provincesFull.map((provinceList, index) => (
                    <option key={index} value={useShipping ? province : provincesAbbreviation[index]} >
                      {useShipping ? `${fullProvince} - ${province}` : `${provinceList} - ${provincesAbbreviation[index]}`}
                    </option>
                  ))}
                </select>
                <input type='text' name='displayCountry' id='displayCountry' value='Canada' required disabled />
              </div>

              {country &&
                <input type='hidden' name='country' id='country' value={country} />
              }

              {/* {result.paymentMethod.id && <input type="hidden" value={result.paymentMethod.id} />} */}

              <div className="payment-form-submit">
                <div className="checkout-form-buttons flex">
                  <div>
                    {checkoutForm &&
                      <Link to={checkoutForm[0].ButtonLink} title={checkoutForm[0].ButtonText}>{checkoutForm[0].ButtonText}</Link>
                    }
                  </div>
                  {checkoutForm &&
                    <button ref={button} id="submit" className="button">{checkoutForm[0].PurchaseButton} <span>{isProcessing ? <i className="fa-solid fa-spinner fa-spin"></i> : ""}</span></button>
                  }
                </div>
              </div>
            </>
          }
          {/* Show any error or success messages */}
          {/* {message && <div id="payment-message">{message}</div>} */}
        </form>
        <div className="checkout-cart-container" ref={cartView}>
          {summary && summary[1].map((content, index) => (
            <React.Fragment key={index}>
              <h2>{content.Heading}</h2>
            </React.Fragment>
          ))}

          {items && Object.keys(items).length > 0 ? (
            <>
              {Object.values(items).map((item, index) => {
                const description = truncate(item.desc, stringLength);
                return (
                  <React.Fragment key={index}>
                    <div key={item.Id} className='cart-item flex'>
                      <div className="cart-image">
                        <Image imageName={item.image} imageTitle={item.desc} lazyOption={'eager'} />
                      </div>
                      <div className="cart-details">
                        <div className="flex">
                          <h3>{item.title}</h3>
                          <h3><strong>${item.price.toFixed(2)}</strong></h3>
                        </div>
                        <p title={item.desc}>{description}</p>

                        <div className="flex">
                          {summary && summary[1].map((content, contentIndex) => (
                            <React.Fragment key={contentIndex}>
                              <h4><i className="fa-solid fa-xmark"></i> {item.Quantity}</h4>
                            </React.Fragment>
                          ))}
                        </div>
                      </div>
                    </div>
                  </React.Fragment>
                );
              })}
            </>
          ) : (
            <div className='empty-cart'>
              {summary && summary[0].map((content, index) => (
                <React.Fragment key={index}>
                  <h2>{content.EmptyCart}</h2>
                </React.Fragment>
              ))}
            </div>
          )}
          <div className="checkout-cart-total">
            {price && summary && summary[1].map((content, index) => (
              <React.Fragment key={index}>
                <h3>{content.Total} <strong>${price}</strong></h3>
              </React.Fragment>
            ))}
          </div>
        </div>
      </div>

    </section>
  );
}

export default CheckoutForm;