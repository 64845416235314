import React, { useEffect, useState } from "react";

// import { useCart } from "./cart-context";
import { useNavigate, useLocation, Link } from "react-router-dom";
import { getErrorPage } from "../../handleData";

function Fail({ language }) {
  const [error, setError] = useState([]);

  const location = useLocation();
  const navigate = useNavigate();

  const queryParams = new URLSearchParams(location.search);
  const encodedIsSuccess = queryParams.get('success');
  const decodedIsSuccess = JSON.parse(decodeURIComponent(encodedIsSuccess));

  useEffect(() => {
    if (decodedIsSuccess === null) {
      navigate('/shop');
    }

  }, [decodedIsSuccess, navigate]);

  useEffect(() => {
    if (language) {
      getErrorPage(language).then(result => {
        setError(result);
      });
    }
  }, [language]);

  return (
    <main className="completion-container">
      <section>
        {error.map((errorPage, index) => (
          <React.Fragment key={index}>
            <h1>{errorPage.Heading}</h1>
            <p>{errorPage.Text}</p>
            <p>{errorPage.Text2} <a href={`mailto:${errorPage.SupportEmail}`}>{errorPage.SupportEmail}</a></p>
            {decodedIsSuccess && decodedIsSuccess.stripeId &&
              <p>An unexpected error occured with your payment. Provide this string of characters to us in your email as this is related to processing your payment: <strong>{decodedIsSuccess.stripeId}</strong></p>
            }
            <Link to={errorPage.ButtonLink} title={errorPage.ButtonText} className="btn">{errorPage.ButtonText}</Link>
          </React.Fragment>
        ))}
      </section>
    </main>
  );
}

export default Fail;
