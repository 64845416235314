import React, { useState } from 'react';
import ReactDOM from 'react-dom';
import App from './App';
import reportWebVitals from './reportWebVitals';
import { CartProvider } from './views/stripe/cart-context';

const Root = () => {
  const [isLoading, setIsLoading] = useState(true);

  const handleAppLoaded = () => {
    setIsLoading(false);
  };

  return (
    <React.StrictMode>
      <CartProvider>
        <App onAppLoaded={handleAppLoaded} />
      </CartProvider>
    </React.StrictMode>
  );
};

ReactDOM.render(
  <Root />,
  document.getElementById("root")
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
