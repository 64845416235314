import React, { useEffect, useRef, useState } from "react";
import { NavLink, Link, useLocation } from 'react-router-dom';
import { toast, ToastContainer } from 'react-toastify';

import Image from '../components/image';

import { getAlerts, getHeader, getLanguages } from "../../handleData";
import { useCart } from "../stripe/cart-context";

function Header({ language, changeLanguage, orderLimitArray }) {
    const [header, setHeader] = useState([]);
    const [languages, setLanguages] = useState(null);
    const [alerts, setAlerts] = useState(null);
    const { cart, cartCounter, addHeaderHeight, updateCart, removeFromCart, clearShipping, clearPrice, clearBilling } = useCart();
    const [items, setItems] = useState([]);
    // const orderLimit = [1, 2, 3, 4, 5, 6, 7, 8, 9, 10];
    const headerElement = useRef(null);
    const cartWidget = useRef(null);
    const cartBtn = useRef(null);
    const location = useLocation();

    if (headerElement.current) {
        if (location.pathname === '/cart' || location.pathname.startsWith('/checkout') || location.pathname.startsWith('/completion') || location.pathname.startsWith('/fail')) {
            headerElement.current.classList.add('cart-header');
        } else {
            headerElement.current.classList.remove('cart-header');
        }
    }

    useEffect(() => {
        if (location.pathname !== '/cart' && location.pathname !== '/checkout') {
            clearShipping();
            clearPrice();
            clearBilling();
        }
    }, [location]);

    useEffect(() => {
        if (language) {
            getHeader(language).then(result => {
                setHeader(result);
            });
        }
    }, [language]);

    useEffect(() => {
        if (headerElement.current) {
            const height = headerElement.current.offsetHeight;
            addHeaderHeight(height);
        }

    }, [addHeaderHeight]);

    useEffect(() => {
        getLanguages().then(result => {
            setLanguages(result);
        });
    }, []);

    useEffect(() => {
        if (language) {
            getAlerts(language).then(result => {
                setAlerts(result[0][0]);
            });
        }
    }, [language]);

    // useEffect(() => {
    //     if (orderLimit) {
    //         const newArray = [];
    //         for (let i = 1; i <= parseInt(orderLimit); i++) {
    //             newArray.push(i);
    //         }
    //         setOrderLimitArray(newArray);
    //     }
    // }, [orderLimit]);

    useEffect(() => {
        setItems(cart);
    }, [cart]);

    useEffect(() => {
        const handleClickOutside = (event) => {
            if (cartWidget.current && !cartWidget.current.contains(event.target)) {
                // Click occurred outside of cart widget, so close the cart widget
                closeWidget();
            }
        };

        document.addEventListener('mousedown', handleClickOutside);
        return () => {
            document.removeEventListener('mousedown', handleClickOutside);
        };
    }, []);

    const showWidget = () => {
        if (cartWidget.current) {
            cartWidget.current.classList.add('show-cart');
        }
    }
    const closeWidget = () => {
        if (cartWidget.current) {
            cartWidget.current.classList.remove('show-cart');
        }
    }

    const removeFromCartBtn = (itemId) => {
        removeFromCart(itemId);
    }

    const setQuantity = (itemId, newQuantity, stock) => {
        if (items && alerts) {
            const updatedItems = { ...items };

            if (stock <= 0 || newQuantity > orderLimitArray.length) {
                toast.error(alerts.LimitExceeded, {
                    position: toast.POSITION.TOP_LEFT,
                });
                return;
            } else if (newQuantity > stock) {
                toast.error(alerts.LimitExceedsStock, {
                    position: toast.POSITION.TOP_LEFT,
                });
                return;
            } else if (newQuantity === orderLimitArray.length || newQuantity === stock) {
                toast.error(alerts.LimitReached, {
                    position: toast.POSITION.TOP_LEFT,
                });
                newQuantity = Math.max(newQuantity, 1);
                updatedItems[itemId].Quantity = newQuantity;
                setItems(updatedItems);
                updateCart(updatedItems);
            } else {
                newQuantity = Math.max(newQuantity, 1);
                updatedItems[itemId].Quantity = newQuantity;
                setItems(updatedItems);
                updateCart(updatedItems);
            }
        }
    };

    useEffect(() => {
        if (location.pathname === '/cart') {
            closeWidget();
            if (cartBtn.current) {
                cartBtn.current.disabled = true;
            }

        } else {
            if (cartBtn.current) {
                cartBtn.current.disabled = false;
            }
            const formInputs = document.querySelectorAll('input');
            const formSelect = document.querySelectorAll('select');
            const selects = document.querySelectorAll('.cart-buttons-contianer select');
            const buttons = document.querySelectorAll('.cart-buttons-container button');

            formInputs.forEach(input => {
                input.disabled = false;
            });

            buttons.forEach(button => {
                button.disabled = false;
            });

            selects.forEach(select => {
                select.disabled = false;
            });

            formSelect.forEach(select => {
                select.disabled = false;
            });
        }
    }, [location]);

    const languageBtn = (selectedLanguage, selectedLanguageAbbr) => {
        changeLanguage(selectedLanguage, selectedLanguageAbbr);
    };

    // const createStockArray = (stock) => {
    //     const array = [];

    //     for (let i = 1; i <= stock; i++) {
    //         array.push(i);
    //     }
    //     return array;
    // }

    const stringLength = 20;

    const truncate = (string, length) => {
        if (string.length > length) {
            return string.slice(0, length) + '...';
        } else {
            return string;
        }
    };

    const openBtn = useRef(null);
    const closeBtn = useRef(null);
    const mobileNav = useRef(null);
    // const [isClosed, setIsClosed] = useState(true);

    useEffect(() => {
        const handleClickOutsideMobile = (event) => {
            if (mobileNav.current && !mobileNav.current.contains(event.target)) {
                // Click occurred outside of cart widget, so close the cart widget
                closeMobile();
            }
        };

        document.addEventListener('mousedown', handleClickOutsideMobile);
        return () => {
            document.removeEventListener('mousedown', handleClickOutsideMobile);
        };
    }, []);

    const openMobile = () => {
        if (openBtn.current && closeBtn.current && mobileNav.current) {
            closeBtn.current.style.display = 'block';
            mobileNav.current.style.display = 'block';
            mobileNav.current.classList.add('animate__slideInRight');
            mobileNav.current.classList.remove('animate__slideOutUp');

        }
    }

    const closeMobile = () => {
        if (openBtn.current && closeBtn.current && mobileNav.current) {
            closeBtn.current.style.display = 'none';
            mobileNav.current.style.display = 'none';
            mobileNav.current.classList.remove('animate__slideInRight');
            mobileNav.current.classList.add('animate__slideOutUp');
        }
    }

    return (
        <>
            <ToastContainer />
            <header className="flex" ref={headerElement}>
                <div className="languages-container flex">
                    {languages && languages.map((languagesText, languagesIndex) => (
                        <button key={languagesIndex} onClick={() => languageBtn(languagesText.Language, languagesText.LanguageAbbr)} title={`Translate to ${languagesText.Language}`}><small>{languagesText.LanguageAbbr}</small></button>
                    ))}
                </div>
                <div className="logo-container">
                    {header.map((header, index) => {
                        if (index === 0) {
                            return (
                                <React.Fragment key={index}>
                                    <NavLink to={'/'} title="Shop" onClick={() => closeMobile()}><Image host={'Shared'} imageName={header.Logo} imageTitle={header.Logo} lazyOption={'eager'} /></NavLink>
                                </React.Fragment>
                            );
                        }
                        return null;
                    })}
                </div>
                <div className="nav-container">
                    <nav className="main-nav">
                        <ul className="flex">
                            {header.map((nav, index) => (
                                <li key={index}><NavLink to={nav.NavLink} className={location.pathname === nav.NavLink ? 'active' : ''} onClick={() => closeMobile()}>{nav.NavItem}</NavLink></li>
                            ))}
                            <li>
                                <button onClick={showWidget} id="cartIcon" title="View Cart" ref={cartBtn}><i className="fa-solid fa-cart-shopping"></i>
                                    {cartCounter > 0 &&
                                        <span>{cartCounter}</span>}
                                </button>
                            </li>
                        </ul>
                    </nav>
                    <button className="btn mobile-button" onClick={openMobile} ref={openBtn}><i className="fa-solid fa-bars"></i></button>
                    <nav className={`mobile-nav animate__animated`} ref={mobileNav}>
                        <button className="mobile-button-close" onClick={closeMobile} ref={closeBtn}><i className="fa-solid fa-xmark"></i></button>
                        <ul className="flex">
                            {header.map((nav, index) => (
                                <li key={index}><NavLink to={nav.NavLink} className={location.pathname === nav.NavLink ? 'active' : ''} onClick={() => closeMobile()}>{nav.NavItem}</NavLink></li>
                            ))}
                            <li className="mobile-cart-button">
                                <button onClick={showWidget} id="cartIcon">
                                    <i className="fa-solid fa-cart-shopping"></i>
                                    {cartCounter > 0 &&
                                        <span>{cartCounter}</span>}
                                </button>
                            </li>
                        </ul>
                    </nav>
                </div>
            </header>
            <div className="cart-widget content" ref={cartWidget}>
                <div className="flex">
                    <h2>Cart Preview</h2>
                    <i className="fa-solid fa-chevron-right" title="Close Cart View" onClick={closeWidget}></i>
                </div>
                {items && Object.keys(items).length > 0 ? (
                    <>
                        {Object.values(items).map((item, index) => {
                            const description = truncate(item.title, stringLength);
                            // const stockArray = createStockArray(item.Stock);
                            return (
                                <React.Fragment key={index}>
                                    <div key={item.Id} className="cart-widget-card">
                                        <div className="cart-image">
                                            <Image imageName={item.image} imageTitle={item.part} />
                                        </div>
                                        <div>
                                            <h3>{description}</h3>
                                        </div>
                                        <div className='cart-buttons-container flex'>
                                            <div className="cart-buttons">
                                                <button onClick={() => setQuantity(item.part, item.Quantity + 1, item.Stock)} disabled={item.Quantity === orderLimitArray.length || item.Quantity === item.Stock} className='button'>+</button>
                                                <button onClick={() => setQuantity(item.part, item.Quantity - 1, item.Stock)} disabled={item.Quantity === 1} className='button'>-</button>
                                            </div>
                                            <div className='cart-button-input'>
                                                {orderLimitArray.length > 0 &&
                                                    <input
                                                        type="number"
                                                        value={parseInt(item.Quantity)} // Convert to integer using parseInt
                                                        onChange={(event) => setQuantity(item.part, parseInt(event.target.value), item.Stock)}
                                                        onBlur={(event) => {
                                                            if (event.target.value === '') {
                                                                setQuantity(item.part, 1, item.Stock);
                                                            }
                                                        }}
                                                    />
                                                    // <select value={item.Quantity} onChange={(event) => setQuantity(item.part, event.target.value, item.Stock)}>
                                                    //     {stockArray.length <= orderLimitArray.length && (
                                                    //         stockArray.map((value, index) => (
                                                    //             <option key={index} value={value}>{value}</option>
                                                    //         ))
                                                    //     )}

                                                    //     {orderLimitArray.length < stockArray.length && (
                                                    //         orderLimitArray.map((value, index) => (
                                                    //             <option key={index} value={value}>{value}</option>
                                                    //         ))
                                                    //     )}
                                                    // </select>
                                                }
                                            </div>
                                            <button onClick={() => removeFromCartBtn(item.part)} title='Remove Item' className='button'><i className="fa-solid fa-trash-can"></i></button>
                                        </div>
                                    </div>
                                </React.Fragment>
                            );
                        })}
                    </>
                ) : (
                    <div className='empty-cart'>
                        <div className="flex">
                            <div>
                                <h3>Cart is Empty</h3>
                            </div>
                            <div>
                                <Link to={'/shop'} className="btn">Show Now</Link>
                            </div>
                        </div>
                    </div>
                )}
                <div className="cart-widget-summary">
                    <Link to={'/cart'} className="button" onClick={() => closeMobile()}>View Cart Summary</Link>
                </div>
            </div >
        </>
    );
}

export default Header;
