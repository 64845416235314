import React, { useEffect, useState, useRef } from "react";
import { toast, ToastContainer } from 'react-toastify';

import Image from "../components/image";
import { getAlerts, getContact, getWidgets, sendContact } from "../../handleData";
import Widget from "../components/widget";

function Contact({ host, language }) {
    const [section1, setSection1] = useState([]);
    const [section2, setSection2] = useState([]);
    const [formValues, setFormValues] = useState([]);
    const [contactImage, setContactImage] = useState([]);
    const [alerts, setAlerts] = useState(null);

    const [name, setName] = useState([]);
    const [email, setEmail] = useState([]);
    const [phone, setPhone] = useState([]);
    const [message, setMessage] = useState([]);

    const contactSection = useRef(null);

    const form = useRef(null);
    const [buttonState, setButtonState] = useState(false);

    useEffect(() => {
        setTimeout(() => {
            if (contactSection.current) {
                contactSection.current.scrollIntoView({
                    behavior: 'smooth'
                });
            }
        }, 0);

    }, [contactSection.current]);

    useEffect(() => {
        if (language) {
            getWidgets("form", language).then(result => {
                setFormValues(result[0]);
            });
        }
    }, [language]);

    useEffect(() => {
        if (language) {
            getAlerts(language).then(result => {
                setAlerts(result[1][0]);
            });
        }
    }, [language]);

    useEffect(() => {
        if (language) {
            getContact(language).then(result => {
                setSection1(result[0]);
                setSection2(result[1]);
                setContactImage(result[2][0]);
            });
        }
    }, [language]);

    const handleSubmit = async (event) => {
        event.preventDefault();
        sendContact("general", name, email, phone, message).then(result => {
            if (result === true) {
                if (alerts) {
                    toast.success(alerts.EmailSent);
                    console.log(alerts)
                    form.current.reset();
                    setButtonState(true);
                }
            } else {
                if (alerts) {
                    toast.error(alerts.EmailSent);
                }
            }
        });
    };

    // if (location.pathname.includes('/contact')) {
    //     const widget = document.querySelector('.contact-widget');
    //     console.log(widget)
    //     widget.style.display = 'none';
    // }
    return (
        <main>
            <ToastContainer />
            <Widget type={"masthead"} host={host} language={language} />
            <section ref={contactSection} className="content contact-container">
                {section1 && section1.map((contact, index) => (
                    <React.Fragment key={index}>
                        <h2>{contact.Heading}</h2>
                    </React.Fragment>
                ))}
                <div className="flex">
                    <div>
                        {section1 && section1.map((contact, index) => (
                            <React.Fragment key={index}>
                                <p>{contact.Text}</p>
                            </React.Fragment>
                        ))}
                        <form className='contact-form' onSubmit={handleSubmit} ref={form}>
                            {formValues &&
                                <>
                                    <div className='flex'>
                                        <input type='text' name='name' id='name' placeholder={formValues.Name} onChange={(event) => setName(event.target.value)} required />
                                    </div>
                                    <div className="flex">
                                        <input type='tel' name='phone' id='phone' placeholder={formValues.Phone} onChange={(event) => setPhone(event.target.value)} maxLength="10" pattern='[0-9]{10}' inputMode='numeric' onKeyDown={(event) => {
                                            const key = event.key;
                                            if (!(key === 'Backspace' || key === 'Tab') && !/^[0-9]+$/.test(key)) {
                                                event.preventDefault();
                                            }
                                        }} />
                                    </div>
                                    <div className='flex'>
                                        <input type='email' name='email' id='email' placeholder={formValues.Email} onChange={(event) => setEmail(event.target.value)} required />
                                    </div>
                                    <div>
                                        <textarea name='message' id='message' placeholder={formValues.Message} rows={5} onChange={(event) => setMessage(event.target.value)} required></textarea>
                                    </div>
                                    <button type='submit' id='submitBtn' className='button' disabled={buttonState}>{formValues.SubmitButton} <i className="fa-solid fa-share"></i></button>
                                </>
                            }
                        </form>
                    </div>
                    <div>
                        {section1 && section1.map((section, index) => (
                            <React.Fragment key={index}>
                                <div>
                                    <p>{section.AddressHeading}</p>
                                    <p>{section.Address}</p>
                                </div>
                            </React.Fragment>
                        ))}

                        {section2 && section2.map((section, index) => (
                            <React.Fragment key={index}>
                                <div className="contact-info">
                                    <p className="flex">{section.ContactHeading}: <strong>{section.ContactInfo}</strong></p>
                                </div>
                            </React.Fragment>
                        ))}
                        {contactImage &&
                            <div className="contact-map">
                                <a href={contactImage.ImageLink} target="_blank" title="View Map" rel="noreferrer">
                                    <Image host={"Shared"} imageName={contactImage.Image} lazyOption={"lazy"} />
                                </a>
                            </div>
                        }
                    </div>
                </div>

            </section>
        </main>
    );
}

export default Contact;