import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";

import Image from "./image";
import { getMasthead, getWidgets } from "../../handleData";
import { HashLink } from "react-router-hash-link";

function Widget({ type, host, language }) {
    const [widget, setWidget] = useState([]);
    const [hostValue, setHostValue] = useState(null);
    const [masthead, setMasthead] = useState(null);

    // const { headerHeight } = useCart();
    useEffect(() => {
        if (host) {
            setHostValue(host);
        }
    }, [host]);

    useEffect(() => {
        if (type && language) {
            getWidgets(type, language).then(result => {
                setWidget(result);
            });
        }

    }, [type, language]);

    useEffect(() => {
        if (host && language) {
            getMasthead(host, language).then(result => {
                setMasthead(result[0][0]);
            });
        }
    }, [host, language]);

    if (type === 'contact') {
        return (
            <div className="content contact-widget">
                {widget.map((contact, index) => (
                    <React.Fragment key={index}>
                        <h2>{contact.Heading}</h2>
                        <div className="flex">
                            <div>
                                <p>{contact.Text}</p>
                            </div>

                            <div>
                                <Link to={contact.ButtonLink} className="btn">{contact.ButtonText}</Link>
                            </div>
                        </div>

                    </React.Fragment>
                ))}
            </div>
        );
    } else if (type === 'masthead' && hostValue && masthead) {
        return (
            <div className="masthead flex">
                <div className="masthead-container flex" /*style={headerHeight > 0 ? { transform: `translateY(-${headerHeight})` } : { transform: `translateY(-130px)` }}*/>
                    <Image host={hostValue} imageName={masthead.Image} imageTitle={masthead.Title} lazyOption={"eager"} />
                    <div className="backdrop"></div>
                    <h1>{masthead.Heading}</h1>
                    <div className="divider"></div>
                    <h2>{masthead.Text}</h2>
                    <div className="masthead-buttons flex">
                        {widget.map((button, buttonIndex) => (
                            <React.Fragment key={buttonIndex}>
                                <HashLink to={button.ButtonLink} title="Shop Now">{button.ButtonText}</HashLink>
                            </React.Fragment>
                        ))}
                    </div>
                </div>
            </div>
        );
    } else {
        return (
            <div></div>
        );
    }
}

export default Widget;