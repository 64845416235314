import React, { useEffect, useState, useRef } from "react";
import { getImages } from "../../handleData";


function Image({ host, imageName, imageTitle, lazyOption }) {
    const [imagePath, setImagePath] = useState('');
    const image = useRef(null);

    useEffect(() => {
        if (imageName) {
            if (imageName.startsWith('http')) {
                setImagePath(imageName);
            } else {
                getImages(host, imageName).then(result => {
                    setImagePath(result);
                });
            }
        }

    }, [imageName, host]);

    // function checkImage(path) {
    //     fetch(path)
    //         .then((res) => {
    //             if (res.status == 404) {
    //                 setImagePath('http://provix.net/crv/img/noimage.gif');
    //             } else {
    //                 setImagePath(imageName);
    //             }
    //         })
    //         .catch((err) => {
    //             setImagePath('http://provix.net/crv/img/noimage.gif');
    //         });
    // }

    // const handleImage = () => {
    //     image.current.src = 'http://provix.net/crv/img/noimage.gif';
    // }

    return (
        // <img src="http://provix.net/crv/img/noimage.gif" alt={imageName} title={imageTitle} loading={lazyOption} />
        <img src={imagePath} alt={imageName} title={imageTitle} loading={lazyOption} ref={image} />
    );
}

export default Image;